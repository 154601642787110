<template>
  <div>
    <divisoria-descricao
      v-if="value.razaoSocial"
      :titulo="`Configurações rotinas e-CAC por empresa - ${value.razaoSocial}`"
      :descricao="`Marque ou desmarque as rotinas que sua empresa tem junto ao e-CAC.`"
      espaco-extra
    />
    <div v-if="value.processos">
      <div
        v-for="(grupo, $index) in processos"
        :key="$index"
        class="mt-1 mb-3"
      >
        <h6
          style="margin-top: 0.15rem"
          class="text-uppercase"
        >
          {{ grupo.nome }}
        </h6>
        <b-row>
          <b-col
            v-for="configuracao in grupo.configuracoes"
            :key="configuracao.nome"
            cols="3"
          >
            <b-form-checkbox
              :checked="selecionado(configuracao.valor)"
              style="margin-top: 0.75rem"
              switch
              @change="selecionar(configuracao.valor)"
            >
              {{ configuracao.descricao }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
    </div>
    <h6
      style="margin-top: 0.15rem"
      class="text-uppercase"
    >
      CERTIFICADO DIGITAL
    </h6>
    <b-row>
      <b-col>
        <b-form-checkbox
          id="responsavelLegal"
          v-model="value.responsavelLegal"
          style="margin-top: 0.75rem"
          switch
        >
          Responsável legal
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ComponenteEcac',
  components: {
    DivisoriaDescricao: () => import('@pilar/components/divisoria-descricao/DivisoriaDescricao.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      processos: this.getPermissaoEcac(),
    }
  },
  methods: {
    selecionar(configuracao) {
      const selecionado = this.value.processos
      const index = selecionado.indexOf(configuracao)
      if (index >= 0) {
        this.value.processos.splice(index, 1)
        if (configuracao === 128 || configuracao === 256) {
          this.value.processos.splice(selecionado.indexOf(configuracao === 128 ? 256 : 128), 1)
        }
      } else {
        this.value.processos.push(configuracao)
        if (configuracao === 128 || configuracao === 256) {
          this.value.processos.push((configuracao === 128 ? 256 : 128))
        }
      }
    },
    selecionado(configuracao) {
      return this.value.processos.includes(configuracao)
    },
    getPermissaoEcac() {
      const permissoes = this.getPermissaoLocal()
      const processos = [{
        nome: 'Federal',
        configuracoes: [{ valor: 1, descricao: 'DCTFWeb' }],
      },
      {
        nome: 'Parcelamentos',
        configuracoes: [{ valor: 2, descricao: 'Simples Nacional' },
          { valor: 4, descricao: 'PERT SN' },
          { valor: 8, descricao: 'RELP SN' },
          { valor: 16, descricao: 'RFB' },
          { valor: 32, descricao: 'Não Previdenciário' },
          { valor: 512, descricao: 'Simplificado Previdenciário' },
          { valor: 64, descricao: 'PGFN' },
        ],
      },
      {
        nome: 'Simples Nacional',
        configuracoes: [{ valor: 128, descricao: 'DAS' },
          { valor: 256, descricao: 'Sublimite' },
        ],
      },
      ]

      return this.filtrarProcessosPorPermissoes(processos, permissoes)
    },
    filtrarProcessosPorPermissoes(processos, permissoesAjustadas) {
      return processos.map(grupo => {
        const configuracoesPermitidas = grupo.configuracoes.filter(config => permissoesAjustadas.includes(config.descricao))
        if (configuracoesPermitidas.length > 0) {
          return { ...grupo, configuracoes: configuracoesPermitidas }
        }
        return null
      })
        .filter(grupo => grupo !== null)
    },
    getPermissaoLocal() {
      const permissoes = localStorage.getItem('permissoes').split(',')
      const array = []
      for (let i = 0; i < permissoes.length; i += 1) {
        if (permissoes[i] === 'declaracao_dctfweb') {
          array.push('DCTFWeb')
        }
        if (permissoes[i] === 'das_parcelamento') {
          array.push('Simples Nacional')
        }
        if (permissoes[i] === 'parcelamento_relpsn') {
          array.push('RELP SN')
        }
        if (permissoes[i] === 'parcelamento_pertsn') {
          array.push('PERT SN')
        }
        if (permissoes[i] === 'parcelamento_rfb') {
          array.push('RFB')
        }
        if (permissoes[i] === 'parcelamento_naoprevidenciario') {
          array.push('Não Previdenciário')
        }
        if (permissoes[i] === 'pgfn_parcelamento') {
          array.push('PGFN')
        }
        if (permissoes[i] === 'parcelamento_simp_previdenciario') {
          array.push('Simplificado Previdenciário')
        }
        if (permissoes[i] === 'sublimite') {
          array.push('Sublimite')
        }
        if (permissoes[i] === 'das') {
          array.push('DAS')
        }
      }
      return array
    },
  },
}
</script>
